import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, ModalController, NavController, ToastController } from '@ionic/angular';
import { ApiService } from 'src/app/api.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-view-student',
  templateUrl: './view-student.page.html',
  styleUrls: ['./view-student.page.scss'],
})
export class ViewStudentPage implements OnInit {

  type: string = 'approved';
  loading = true;
  public count = 0;
  public itemsPerPage = 10;
  public currentPage = 1;
  public totalStudents = 0; // Total number of students
  public newRegistration: any[] = [];
  public approvedStudent: any[] = [];
  public disapprovedStudent: any[] = [];

  public isSelected: any;

  public selectedStudentsNew: any[] = [];
  public selectedStudents: any[] = [];
  public selectedStudents2: any[] = [];

  public approvePage: number = 0;
  public disapprovePage: number = 0;
  public newPage: number = 0;

  public franchiseData: any;
  public fdata: any = {
    franchises_id: ""
  }
  public sdata: any = {
    id: ''
  }
  public studentData: any[] = [];
  public temp: any[] = [];

  public search_string_new: any;
  public search_string_approved: any;
  public search_string_disapproved: any;
  @ViewChild('pdfTable')
  pdfTable!: ElementRef;
  constructor(
    public afs: AngularFirestore,
    public api: ApiService,
    public modalController: ModalController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public actionSheetController: ActionSheetController,
    public router: Router,
    public toastController: ToastController,
    public Zone: NgZone,
    public NavCtrl: NavController
  ) {

  }
  ionViewWillEnter() {
    //this.loading = true;
    this.franchiseData = JSON.parse(localStorage.getItem('franchiseProfile'));
    this.selectedStudents = [];
    this.selectedStudents2 = [];
    this.selectedStudentsNew = [];

    this.newRegistration = [];
    this.newPage = 0;

    this.approvedStudent = [];
    this.approvePage = 0;

    this.disapprovedStudent = [];
    this.disapprovePage = 0;

    this.approveData();
  }
  //!
  async newData() {

    // const toast = await this.toastController.create({
    //   message: 'No more data',
    //   duration: 3000,
    // });
    // let data = {
    //   franchises_id: this.franchiseData.registration_no
    // }
    // this.newPage = this.newPage + 1
    this.loading = true;
    this.api.newStudents(this.franchiseData.registration_no, this.currentPage, this.itemsPerPage).subscribe((result) => {

      // if (result['status'] == true) {
      // this.newRegistration = result['data'];
      this.newRegistration = result['res'];
      this.totalStudents = result['data']['totalStudents'];
      this.loading = false;
      //   if (ev) {
      //     setTimeout(() => {
      //       (ev as InfiniteScrollCustomEvent).target.complete();
      //     }, 500);
      //   }
      console.log(this.newRegistration);
      // } else {
      //   this.loading = false;
      //   toast.present();
      //   if (ev) {
      //     setTimeout(() => {
      //       (ev as InfiniteScrollCustomEvent).target.complete();
      //     }, 500);
      //   }
      // }

    }, err => {
      this.loading = false;
      console.log('something went wrong!');
      // if (ev) {
      //   setTimeout(() => {
      //     (ev as InfiniteScrollCustomEvent).target.complete();
      //   }, 500);
      // }
    })
  }

  onChange(page: number) {
    console.log(page, "page")
    this.currentPage = page; // Set the current page
    this.approveData(); // Fetch data for the new page
    // this.disapproveData(); // Fetch data for the new page
  }
  onPageChange(page: number) {
    console.log(page, "page")
    this.currentPage = page; // Set the current page
    // this.approveData(); // Fetch data for the new page
    this.disapproveData(); // Fetch data for the new page
  }
  onPageChangeNewStudent(page: number) {
    console.log(page, "page")
    this.currentPage = page; // Set the current page
    // this.approveData(); // Fetch data for the new page
    this.newData(); // Fetch data for the new page
  }

  async approveData() {

    // const toast = await this.toastController.create({
    //   message: 'No more data',
    //   duration: 3000,
    // });
    // let data = {
    //   franchises_id: this.franchiseData.registration_no,
    //   // count: 10
    // }
    // this.approvePage = this.approvePage + 1
    this.loading = true;
    this.api.approvedStudents(this.franchiseData.registration_no, this.currentPage, this.itemsPerPage).subscribe((result) => {
      console.log(result, "get approved student result");
      // if (result['status'] == true) {
      this.approvedStudent = result['res'];
      this.totalStudents = result['data']['totalStudents'];
      this.loading = false;
      //   if (ev) {
      //     setTimeout(() => {
      //       (ev as InfiniteScrollCustomEvent).target.complete();
      //     }, 500);
      //   }
      console.log(this.approvedStudent);
      this.temp = this.approvedStudent;
      // } else {
      //   this.loading = false;
      //   toast.present();
      //   console.log('no more data');
      //   if (ev) {
      //     setTimeout(() => {
      //       (ev as InfiniteScrollCustomEvent).target.complete();
      //     }, 500);
      //   }
      // }

    }, err => {
      this.loading = false;
      console.log('something went wrong!');
      // if (ev) {
      //   setTimeout(() => {
      //     (ev as InfiniteScrollCustomEvent).target.complete();
      //   }, 500);
      // }
    })
  }
  async disapproveData() {
    // this.loading = true;
    // const toast = await this.toastController.create({
    //   message: 'No more disapproveData',
    //   duration: 3000,
    // });
    // let data = {
    //   franchises_id: this.franchiseData.registration_no
    // }
    // this.disapprovePage = this.disapprovePage + 1
    this.loading = true;
    this.api.disapprovedStudents(this.franchiseData.registration_no, this.currentPage, this.itemsPerPage).subscribe((result) => {
      console.log(result, "get dis--approved student result");
      // if (result['status'] == true) {
      this.disapprovedStudent = result['res'];
      this.totalStudents = result['data']['totalStudents'];
      this.loading = false;
      //   if (ev) {
      //     setTimeout(() => {
      //       (ev as InfiniteScrollCustomEvent).target.complete();
      //     }, 500);
      //   }
      console.log(this.disapprovedStudent);
      // } else {
      //   this.loading = false;
      //   toast.present();
      //   console.log('no more data');
      //   if (ev) {
      //     setTimeout(() => {
      //       (ev as InfiniteScrollCustomEvent).target.complete();
      //     }, 500);
      //   }
      // }

    }, err => {
      this.loading = false;
      console.log('something went wrong!');
      // if (ev) {
      //   setTimeout(() => {
      //     (ev as InfiniteScrollCustomEvent).target.complete();
      //   }, 500);
      // }
    })
  }
  // newIonInfinite(ev) {
  //   this.newData(ev);

  // }
  // approveIonInfinite(ev) {
  //   this.approveData(ev);

  // }
  // disapproveIonInfinite(ev) {
  //   this.disapproveData();

  // }
  //!

  //!searching............. 
  searchNew() {
    console.log(this.search_string_new);
    let data = {
      string: this.search_string_new,
      franchise_id: this.franchiseData.registration_no
    }
    this.loading = true;
    this.api.searchNewStudent(data).subscribe((result) => {
      console.log(result['data'])
      if (this.search_string_new) {
        this.newRegistration = result['data'];
        this.loading = false;
      } else {
        this.newRegistration = [];
        this.newPage = 0;
        this.newData();
      }

    }, err => {
      this.loading = false;
      console.log(err);
    })
  }
  filterItemsNew(ev: any) {

    var val = ev.target.value;

    if (!val) {
      this.newRegistration = [];
      this.newPage = 0;
      this.newData();
    }
  }
  searchApprove() {
    console.log(this.search_string_approved);
    let data = {
      string: this.search_string_approved,
      franchise_id: this.franchiseData.registration_no
    }
    this.loading = true;
    this.api.searchApproveStudent(data).subscribe((result) => {
      console.log(result['data'])
      if (this.search_string_approved) {
        this.approvedStudent = result['data'];
        this.loading = false;
      } else {
        this.approvedStudent = [];
        this.newPage = 0;
        this.approveData();
      }

    }, err => {
      this.loading = false;
      console.log(err);
    })
  }
  filterItemsApprove(ev: any) {
    var val = ev.target.value;
    if (!val) {
      this.approvedStudent = [];
      this.approvePage = 0;
      this.approveData();
    }
  }
  searchDisapprove() {
    console.log(this.search_string_disapproved);
    let data = {
      string: this.search_string_disapproved,
      franchise_id: this.franchiseData.registration_no
    }
    this.loading = true;
    this.api.searchDisapproveStudent(data).subscribe((result) => {
      console.log(result['data'])
      if (this.search_string_disapproved) {
        this.disapprovedStudent = result['data'];
        this.loading = false;
      } else {
        this.disapprovedStudent = [];
        this.disapprovePage = 0;
        this.disapproveData();
      }

    }, err => {
      this.loading = false;
      console.log(err);
    })
  }
  filterItemsDisapprove(ev: any) {
    //   console.log(ev)
    var val = ev.target.value;
    //   console.log(val);
    if (!val) {
      this.disapprovedStudent = [];
      this.disapprovePage = 0;
      this.disapproveData();
    }
  }
  //!....................




  ngOnInit() {
  }
  close() {
    this.modalController.dismiss();
  }
  segmentChanged(ev: any) {
    console.log('Segment changed', ev);
    this.selectedStudentsNew = [];
    this.selectedStudents = [];
    this.selectedStudents2 = [];

    if (ev.detail.value == 'new') {
      this.newData();
    } else if (ev.detail.value == 'approved') {
      this.approveData();
    } else {
      this.disapproveData();
    }

  }

  onChangeNew(event, student) {
    let index = this.selectedStudentsNew.findIndex(x => x.id === student.id);
    if (index != -1) {
      this.selectedStudentsNew.splice(index, 1);
    } else {
      let data = {
        id: student.id,
      }
      this.selectedStudentsNew.push(data);
    }
    console.log(this.selectedStudentsNew);
  }

  onChangeDisapprove(event, student) {
    let index = this.selectedStudents.findIndex(x => x.id === student.id);
    if (index != -1) {
      this.selectedStudents.splice(index, 1);
    } else {
      let data = {
        id: student.id,
      }
      this.selectedStudents.push(data)
    }
    console.log(this.selectedStudents);
  }

  onChangeApprove(event, student) {
    let index = this.selectedStudents2.findIndex(x => x.id === student.id);
    if (index != -1) {
      this.selectedStudents2.splice(index, 1);
    } else {
      let data = {
        id: student.id,
      }
      this.selectedStudents2.push(data)
    }
    console.log(this.selectedStudents2);
  }


  async disapprove2() {

    const alert1 = await this.alertController.create({
      header: 'Do you want to Disapprove!',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {

            let data = {
              students: this.selectedStudents,
              status: false,
            }
            console.log('data is here', data)
            this.api.approveDisapproveNewStudent(data).subscribe((result) => {
              console.log(result);
              this.ionViewWillEnter();
              //  this.type = 'disapproved';
            }, err => {
              console.log('server error', err);
            })

          }
        }]
    });


    await alert1.present();

  }



  async approve2() {
    const alert1 = await this.alertController.create({
      header: 'Do you want to Approve!',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {

            let data = {
              students: this.selectedStudents2,
              status: true,
            }
            this.api.approveDisapproveNewStudent(data).subscribe((result) => {
              console.log(result);
              this.ionViewWillEnter();

              // this.type = 'approved';

            }, err => {
              console.log('server error', err);
            })

          }
        }]
    });
    await alert1.present();

  }


  async deleteProfile(student) {
    this.sdata.id = student.id;
    console.log(student)
    const alert = await this.alertController.create({
      header: 'Do you want to delete!',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: async () => {
            this.loading = true;
            const toast = await this.toastController.create({
              message: 'deleted Successfully',
              duration: 3000,
              mode: 'ios'
            });

            this.api.deleteStudent(this.sdata).subscribe((result) => {
              console.log('deleted');
              // this.approvedStudent = [];
              // let data = {
              //   franchises_id: student.franchises_id
              // }
              // this.api.approvedStudents(data).subscribe((result1) => {
              //   if (result1['status'] == true) {
              //     // this.page = 1;
              //     this.approvedStudent = result1['data'];
              //     this.loading = false;
              //     console.log(this.approvedStudent);
              //     this.temp = this.approvedStudent;
              //   } else {
              //     console.log('no ')
              //   }
              // }, err => {
              //   console.log('no')
              // })
              // this.ionViewWillEnter();
              this.NavCtrl.back();
              toast.present();
            }, err => {
              console.log('server Error')

            });

          }
        }
      ]
    });

    await alert.present();
  }



  async newApprove() {
    const alert1 = await this.alertController.create({
      header: 'Do you want to Approve!',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {

            let data = {
              students: this.selectedStudentsNew,
              status: true,
            }
            this.api.approveDisapproveNewStudent(data).subscribe((result) => {
              console.log(result);


              this.ionViewWillEnter()
            }, err => {
              console.log('server error', err);
            })
          }
        }]
    });
    await alert1.present();

  }
  async newDisapprove() {
    const alert1 = await this.alertController.create({
      header: 'Do you want to Disapprove!',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {

            let data = {
              students: this.selectedStudentsNew,
              status: false,
            }
            this.api.approveDisapproveNewStudent(data).subscribe((result) => {
              console.log(result);
              this.ionViewWillEnter();
              // this.type = 'disapproved';

            }, err => {
              console.log('server error', err);
            })
          }
        }]
    });


    await alert1.present();

  }
  edit(student) {
    localStorage.setItem('studentId', student.id);
    console.log('student', student.id)
    this.router.navigateByUrl('edit-student');
  }
  async presentActionSheet(student) {
    let id = {
      franchises_id: student.franchises_id,
      student_id: student.id
    }
    localStorage.setItem('studentData', JSON.stringify(id))
    console.log(student.id)
    const actionSheet = await this.actionSheetController.create({

      cssClass: 'my-custom-class',

      buttons: [
        {
          text: 'Certificate & Marksheet',
          icon: 'clipboard',
          data: 'Data value',
          handler: async () => {
            console.log('i card clicked');

            await actionSheet.dismiss().then(() => {
              this.Zone.run(() => {
                this.router.navigate(['certificate-marksheet']);
              });
              //this.router.navigateByUrl('certificate-marksheet');

            })

          }
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }]
    });
    await actionSheet.present();


  }
  public downloadAsPDF() {
    let div = this.pdfTable.nativeElement;

    var img: any;
    var filename;
    var newImage: any;


    domtoimage.toPng(div, { bgcolor: '#fff' })

      .then(function (dataUrl) {

        img = new Image();
        img.src = dataUrl;
        newImage = img.src;

        img.onload = function () {

          var pdfWidth = img.width;
          var pdfHeight = img.height;

          // FileSaver.saveAs(dataUrl, 'my-pdfimage.png'); // Save as Image

          var doc;

          if (pdfWidth > pdfHeight) {
            doc = new jsPDF('l', 'px', [pdfWidth, pdfHeight]);
          }
          else {
            doc = new jsPDF('p', 'px', [pdfWidth, pdfHeight]);
          }


          var width = doc.internal.pageSize.getWidth();
          var height = doc.internal.pageSize.getHeight();


          doc.addImage(newImage, 'PNG', 10, 10, width, height);
          filename = 'Student_List' + '.pdf';
          doc.save(filename);

        };


      })
      .catch(function (error) {

        // Error Handling

      });

  }


}
