import { Component } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { ApiService } from './api.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  currentPageTitle = 'Dashboard';

  appPages = [
    {
      title: 'Dashboard',
      url: '',
    },
  ];
  constructor(public nav: NavController, public api: ApiService, public alertController: AlertController) {
    if (localStorage.getItem('admin-profile')) {
      this.nav.navigateRoot('home-admin');
    } else {
      this.nav.navigateRoot('login');
    }
    //this.nav.navigateRoot('view-questions');
  }
  dashboard() {
    this.nav.navigateRoot('home-admin');
  }
  viewFrenchises() {
    this.nav.navigateRoot('view-frenchises');
  }
  addFrenchises() {
    this.nav.navigateRoot('add-frenchises');
  }
  addQuestions() {
    this.nav.navigateRoot('add-questions');
  }
  viewQuestions() {
    this.nav.navigateRoot('view-questions');
  }
  documentGenerator() {
    this.nav.navigateRoot('document-generator/home');
  }
  galleryPhoto() {
    this.nav.navigateRoot('gallery');
  }
  bannerPhoto() {
    this.nav.navigateRoot('banner');
  }

  links() {
    this.nav.navigateRoot('youtube-links');
  }

  async logout() {
    const alert = await this.alertController.create({
      header: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => { }
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => {
            this.nav.navigateRoot('login').then(res => {
              this.api.showMenu = false;
              localStorage.clear();

            })
          }
        }
      ]
    });
    await alert.present();
  }
  franchiseInquiry() {
    this.nav.navigateRoot('franchise');
  }
  studentInquiry() {
    this.nav.navigateRoot('student');
  }
  franchiseRequest() {
    this.nav.navigateRoot('franchise-request');
  }
  deletedFranchises() {
    this.nav.navigateRoot('deleted-franchises');
  }
  ExamResult() {
    this.nav.navigateRoot('student-exam-result');
  }
  subject() {
    this.nav.navigateRoot('subject');
  }
}
