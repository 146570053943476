// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-split-pane {
  --side-width:10px;
}

ion-content {
  --background:rgb(60, 30, 77);
  color: white;
}

ion-list {
  background: rgb(60, 30, 77);
  color: white;
}

ion-list-header {
  color: white;
}

ion-item {
  --background:rgb(60, 30, 77);
  color: white;
  --border-color: gray;
}
ion-item ion-icon {
  color: rgb(207, 202, 202);
  font-size: 18px;
}
ion-item .menu-text {
  font-size: 14px;
  margin-left: 10px;
  color: rgb(207, 202, 202);
}
ion-item .menu-text:hover {
  color: white;
}

.dashboard-item {
  margin-right: 20px;
}
.dashboard-item .dashboard {
  color: white;
  margin-left: 15px;
  font-size: 14px;
}
.dashboard-item ion-icon {
  color: rgb(207, 202, 202);
  font-size: 20px;
}

.item-second {
  --background:rgb(60, 30, 77);
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  --border-color: gray;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,4BAAA;EACA,YAAA;AACF;;AACA;EACE,2BAAA;EACA,YAAA;AAEF;;AAAA;EACE,YAAA;AAGF;;AADA;EACE,4BAAA;EACA,YAAA;EACA,oBAAA;AAIF;AAFE;EACI,yBAAA;EACA,eAAA;AAIN;AAAE;EACI,eAAA;EACA,iBAAA;EACA,yBAAA;AAEN;AAAE;EACI,YAAA;AAEN;;AACA;EACE,kBAAA;AAEF;AADE;EACI,YAAA;EACA,iBAAA;EACA,eAAA;AAGN;AADE;EACI,yBAAA;EACA,eAAA;AAGN;;AAAA;EACE,4BAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,oBAAA;AAGF","sourcesContent":["ion-split-pane{\n  --side-width:10px;\n \n}\nion-content{\n  --background:rgb(60, 30, 77);\n  color: white;\n}\nion-list{\n  background:rgb(60, 30, 77);\n  color: white;\n} \nion-list-header{\n  color: white;\n} \nion-item{\n  --background:rgb(60, 30, 77);\n  color: white;\n  --border-color: gray;\n\n  ion-icon{\n      color: rgb(207, 202, 202);\n      font-size: 18px;\n  }\n \n  \n  .menu-text{\n      font-size: 14px;\n      margin-left: 10px;\n      color: rgb(207, 202, 202);\n  }\n  .menu-text:hover{\n      color: white;\n  }\n}\n.dashboard-item{\n  margin-right: 20px;\n  .dashboard{\n      color: white;\n      margin-left: 15px;\n      font-size: 14px;\n  }\n  ion-icon{\n      color: rgb(207, 202, 202);\n      font-size: 20px;\n  }\n}\n.item-second{\n  --background:rgb(60, 30, 77);\n  color: white;\n  margin-left: 10px;\n  margin-right: 10px;\n  border-radius: 10px;\n  --border-color: gray;\n}\n \n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
